/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing Swiper CSS file. */
@import "~swiper/css/bundle";

@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

//import fonts

@font-face {
  font-family: "CodecPro";
  src: local("CodecPro"), url("./assets/fonts/CodecPro-Regular.eot");
  src: local("CodecPro"),
    url("./assets/fonts/CodecPro-Regular.woff") format("woff"),
    url("./assets/fonts/CodecPro-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "CodecPro-Fat-Italic";
  src: local("CodecPro-Fat-Italic"),
    url("./assets/fonts/Codec-Pro-Fat-Italic.eot");
  src: local("CodecPro-Fat-Italic"),
    url("./assets/fonts/Codec-Pro-Fat-Italic.woff") format("woff"),
    url("./assets/fonts/Codec-Pro-Fat-Italic.ttf") format("ttf");
}

@font-face {
  font-family: "CodecPro-Heavy";
  src: local("CodecPro-Heavy"), url("./assets/fonts/Codec-Pro-Heavy.eot");
  src: local("CodecPro-Heavy"),
    url("./assets/fonts/Codec-Pro-Heavy.woff") format("woff"),
    url("./assets/fonts/Codec-Pro-Heavy.ttf") format("ttf");
}

@font-face {
  font-family: "CodecPro-Light";
  src: local("CodecPro-Light"), url("./assets/fonts/Codec-Pro-Light.eot");
  src: local("CodecPro-Light"),
    url("./assets/fonts/Codec-Pro-Light.woff") format("woff"),
    url("./assets/fonts/Codec-Pro-Light.ttf") format("ttf");
}

@font-face {
  font-family: 'almoni-regular';
  src: local("almoni-regular"), url("./assets/fonts/almoni-neue-regular-aaa.otf") format("otf");
}

@font-face {
  font-family: 'almoni-bold';
  src: local("almoni-ultra"), url("./assets/fonts/almoni-neue-ultrablack-aaa[52386493].otf") format("otf");
}

/* You can add global styles to this file, and also import other style files */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

ul[class],
ol[class] {
  padding: 0;
}

body,
div h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  // line-height: normal;
  letter-spacing: normal;
}

span {
  word-break: break-word;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #f4f6f8;
  font-family: "CodecPro";
  max-width: 100vw;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  margin-left: -10px;
}

body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

body::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 20px;
}

ul[class],
ol[class] {
  list-style: none;
}

a {
  display: block;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
  display: block;
}

article > * + * {
  margin-top: 1em;
}

.hebrew-font {
  font-family: "Secular One", sans-serif !important;
  font-weight: 400 !important;
}

.text-dir-rtl {
  direction: rtl !important;
  text-align: right !important;
}

input,
button,
textarea,
select {
  font: inherit;
}

button {
  //text-align: left;
  background: none;
  border: 0;
  color: inherit;
  /* cursor: default; */
  font: inherit;
  //line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: button; /* for input */
}

input,
input:focus {
  background-color: none;
  border: none;
  outline: none;
}

table {
  width: 100%;
}

table,
td,
th {
  border: 1px solid #000 !important;
  border-collapse: collapse;
}

textarea {
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

//general styles

.app-wrapper {
  // max-width: 1366px;
  // margin: 0 auto;
}

//customizing ng slider

.carousel {
  width: 100%;
  // max-width: 833px;
  max-width: 1132px;
    max-height: 173px;
    // margin-right: auto;
    margin-left: auto;
    border-radius: 24px;
}

.carousel-control-prev-icon {
  background-image: url("./assets/images/global/left-arrow.png") !important;
  width: 12px;
  height: 21px;
}

.carousel-control-next-icon {
  background-image: url("./assets/images/global/right-arrow.png") !important;
  width: 12px;
  height: 21px;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
  bottom: 0px;
}

.carousel-control-prev {
  width: 6%;
  left: 0px;
}

.carousel-control-next {
  width: 6%;
}

body .carousel-indicators {
  position: absolute;
  bottom: 14px;
  right: 20px;
  left: auto;
  display: flex;
  justify-content: space-between;
}

body .carousel-indicators li {
  margin-left: 10px;
  opacity: 1;
  width: 8px;
  height: 8px;
  background-color: rgba(216, 216, 216, 0.39);
  border-radius: 100%;
}

body .carousel-indicators li.active {
  background-color: #f9f9f9;
}

body .carousel-inner {
  align-self: flex-end;
}

//there is a style for Modal Apps page (add apps modal)

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0 auto;
}

.modal-content {
  border: none;
  border-radius: 15px;
  box-shadow: 0 7px 10px 1px #d6dbe1;
  background-color: #fff;
  max-width: 440px;
}

.modal-backdrop.show {
  opacity: 0.81;
}

.modal-backdrop {
  background-color: #f4f6f8;
}

.highlight {
  background-color: #6ed7727d;
  font-weight: bold;
}

.menu-item--active {
  font-family: "CodecPro-Heavy" !important;
  background-color: #fff !important;
  background-color: rgba(255, 218, 107, 0.3) !important;
  font-weight: 800 !important;
}

.menu-item--active-light {
  font-family: "CodecPro-Heavy" !important;
  // background-color: #fff !important;
  // background-color: rgba(112, 200, 255, 0.80) !important;
  font-weight: 800 !important;
}

:host ::ng-deep child.contact__image {
  img {
    margin-top: -20px;
    // margin-right: -60px;
    display: inline-flex;
  }
}

@media screen and (max-width: 768px) {
  // body {
  //   margin-top: 50px;
  // }
}

.content-action {
  &__item {
    display: flex;
    padding: 7px;
    align-items: center;
    border-radius: 5px;
  }

  &__item:hover {
    background-color: #fef4d3;
  }

  &__image {
    margin-right: 9px;
  }

  &__text {
    font-size: 15px;
    line-height: 1.13;
    letter-spacing: 0.2px;
    color: #000;
  }
}

.edit-content {
  display: flex;
  flex-direction: column;
  min-width: 122px;

  &__item {
    display: flex;
    padding: 7px;
    align-items: center;
    border-radius: 5px;
  }

  &__item:hover {
    background-color: #fef4d3;
  }

  &__image {
    margin-right: 9px;
  }

  &__text {
    font-size: 15px;
    line-height: 1.13;
    letter-spacing: 0.2px;
    color: #000;
  }
}

::ng-deep .heigth-line div {
  line-height: normal !important;
}

.return-link {
  font-weight: bold;
  color: #ff874f;
  letter-spacing: 0.44px;
  cursor: pointer;
}

@media screen and (min-width: 1281px) and (max-width: 1660px){
  .carousel{
    max-width: 970px;
    max-height: 153px;
  }
}


@media screen and (max-width: 1500px){
  .carousel{
    max-width: 832px;
    max-height: 153px;
  }
}

@media screen and (max-width: 1280px) {
  .carousel{
    max-width: 832px;
    max-height: 153px;
  }
}